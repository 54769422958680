.fade-in-delayed {
  animation: customFadeIn 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.fade-in-immediate {
  animation: customFadeIn 0.5s;
  animation-fill-mode: forwards;
}

@keyframes customFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

h1, h2, h3, h4 {
  color: #344148;
  font-weight: 300;
  font-family: "Cormorant Garamond" !important;
}

p {
  font-family: Cabin
}

button {
  font-family: Cabin !important;
}