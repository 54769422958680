.link-wrapper::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #97adca;
  transition: 0.2s;
  position: relative;
  bottom: 4px;
  margin: 2px auto 0;
}

.link-wrapper:hover::after {
  width: 35px;
  transition: width 0.2s;
}

.link-active::after {
  content: "";
  position: relative;
  bottom: 2px;
  display: block;
  margin: 0 auto;
  width: 35px;
  border-bottom: 2px solid #00437f;
}

p {
  font-family: "Cabin"
}