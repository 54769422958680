*:focus {
  box-shadow: none !important;
}

*[data-focus] {
  box-shadow: none !important;
}

* {
  box-sizing: border-box !important;
}

html,
body {
  overflow-x: hidden;
}

p {
  color: #344148;
  font-weight: 400;
}

